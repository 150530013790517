<template>
	<div id="landList">
		<page-polling :is-shop="1"></page-polling>
	</div>
</template>
<script>
	import pagePolling from '@/components/layout/land/page-polling.vue'
	export default{
		components:{
			pagePolling
		},
	}
</script>

<style>
</style>
